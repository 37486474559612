/* App.js */

.app-container {
    background-color: #d3e0ea;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:  radial-gradient(#444CF7 0.9500000000000001px, transparent 0.9500000000000001px), radial-gradient(#444CF7 0.9500000000000001px, #E5E5F7 0.9500000000000001px);
    background-size: 38px 38px;
    background-position: 0 0,19px 19px;
    /* overflow: scroll; */
}

.app {
    overflow: scroll;
    position: relative;
    padding: 20px 20px 0 20px;
    max-width: 720px;
    width: 100%;
    /* height: 100%; */
    max-height: 600px;
    background-color: #c0e218;
    overflow: scroll;
    border-radius: .25rem;
    min-width: 375px;
    -webkit-box-shadow: 0px 10px 15px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 15px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 15px -8px rgba(0,0,0,0.75);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app::-webkit-scrollbar {
    display: none;
}

.fill-window {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
}

/* Header */

.header-container {
    position: relative;
}

.header {
    text-align: center;
}

.header > h1 {
    color: #111d5e; 
    /* font-family: 'Gloria Hallelujah', cursive; */
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.calendar-toggle-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

#calendar-toggle-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

#list-icon, #calendar-icon {
    padding: 5px;
    box-sizing: content-box;
    transform: scale(1.5);
    margin: 5px;
    margin-bottom: 10px;
    color: white;
}


.fc .fc-button-primary {
    background-color: #111d5e !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
}

.fc-v-event {
    background-color: #111d5e !important;
}

/* Text Field */

.text-field-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

#text-input {
    background-color: white;
    padding: 10px 10px;
    width: 50%;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
}

#text-field-btn {
    background-color: #111d5e;
    padding: 10px 15px;
    color: white;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

/* Calendar view */

.view-container {
    margin-top: 20px;
}

.fc-scroller::-webkit-scrollbar, .fc-scroller-liquid-absolute::-webkit-scrollbar {
    display: none;
} 

.fc-scroller, .fc-scroller-liquid-absolute {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.fc-toolbar-title {
    color: #f37121;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
}



/* Todo List Items */

.item-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
}

.item-left-container {
    width: 100%;
}

.item-right-container {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
}


/* Attachment */

.attachment-container > a {
    padding-right: 10px;
}

#cross-mark {
    font-size: medium;
    cursor: pointer;
}

/* Filter Menu */

.filter-menu {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: auto;
    padding: 10px 0;
    
}

.filter-menu > li {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
}

.filter-menu > li:hover {
    color: #f37121;
}

.selected {
    color: #f37121 !important;
}


/* Accordion */

.flagged {
    background: #111d5e !important;
    color: white;
}

.strikethrough {
    text-decoration:line-through;
}

.card {
    margin: 10px;
    border-radius: .25rem !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    background: transparent !important;
}

/* .card-header {
    display: flex;
    justify-content: space-between;
    font-family: 'Gloria Hallelujah', cursive;
} */

.item-icons-container {
    display: flex;
    width: 10%;
    justify-content: space-between;
}

.item-icons-container > div {
    cursor: pointer;
}

/* Todo Title */

.todo-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Gloria Hallelujah', cursive;
}

#todo-title {
    margin-bottom: 0;
    text-transform: capitalize;
    max-width: 90%;
    box-sizing: border-box;
    padding-right: 10px;
}

.todo-title-input-container {
    display: flex;
}

#title-input {
    border-bottom: 1px solid #f37121;
    margin-right: 15px;
    width: 100%;
    padding: 10px;
}

#save-icon {
    font-size: 20px;
    /* background-color: black; */
    color: #f37121;
    cursor: pointer;
}

/* Details */

.data-container {
    min-height: 340px;
}

.item-detail-header {
    display: flex;
}

.item-detail-container {
    margin-bottom: 10px;
}

.item-detail-container  p {
    font-family: 'Gloria Hallelujah', cursive;
    word-break: break-word;
}

.details-h2 {
    font-size: 16px !important;
    font-weight: bold;
}

#edit-icon-container {
    position: relative;
}

#edit-icon {
    position: absolute;
    right: -30px;
    cursor: pointer;
}


textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 10px;
  background: white;
}

input[type="submit"] {
  border: none;
  background: #111d5e;
  padding: 8px 17px;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
}


/* Todo List Due Date */

.due-date-container {
    font-family: 'Gloria Hallelujah', cursive;
}

.item-due-date-header {
    display: flex;
}



/* File Attachment */

.file-upload-container {
    margin-bottom: 10px;
}

.attachment-container {
    display: flex;
    font-family: 'Gloria Hallelujah', cursive;
    /* word-break: break-word; */
}

/* Toggle Btn */

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}

.label-text {
  margin-left: 16px;
}

.toggle {
  isolation: isolate;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;

}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background: #111d5e;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}

/* Emojis */

.emoji-container {
    display: flex;
    justify-content: center;
    align-items: center ;
    flex-direction: column;
}

#emoji {
    font-size: 90px;
    margin: 40px 0;
    /* font-family: "Open Sans",sans-serif;
    font-weight: 200 !important; */
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.emoji-container > p {
    font-size: 50px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
}

/* Footer */

#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-icons-container  a {
    padding: 10px;
    font-size: 25px;
}

#author-signature{
    font-family: 'Gloria Hallelujah', cursive;

}

/* Clear Data Trash Can*/

.clear-data-container {
    position: relative;
    font-size: 30px;
    cursor: pointer;
    color: #111d5e;
}

#clear-data-btn {
    position: absolute;
    bottom: 25px;
    right: 15px;
}

/* Settings Modal */

.modal-container > button  {
    position: absolute;
    top: 20px;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.modal-content {
  position:absolute;
  background: white;
  width: 60% !important;
  height: auto !important;
  border-radius: 0.25rem;
  min-height: 30%;
}

.display-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-none {
    display: none;
}

#modal-close-btn {
    position: absolute;
    right: 20px;
    bottom: 10px;
}



/* Settings */

.settings-container {
    padding: 20px;
    padding-bottom: 40px;
}

#settings-icon {
    font-size: 20px;
}

#sticky-title-input{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 6px 0 7px;
}

.color-picker-container {
    margin-top: 10px;
}

#default-settings-btn {
    position: absolute;
    bottom: 10px;

}
/* Mobile Optimation */


@media only screen and (max-width: 420px) {

    .app {
        height: 100%;
        max-height: 100%;
    }

    #calendar-toggle-container {
        transform: scale(0.65);
        right: -15px;
    }

    .calendar-toggle-container {
        flex-direction: column;
    }


    .calendar-toggle-container > .flagg-container {
        -ms-transform: rotate(50deg); /* IE 9 */
        transform: rotate(50deg);
        margin: 10px;
    }

    /* calendar view */

    .view-container {
        height: 100%;
    }

    .calendar-container {
        height: 100%;
    }

    .calendar-container > div {
        height: 100% !important;
    }

    .fc .fc-toolbar {
        flex-direction: column;
    }

    .fc-toolbar-chunk {
        transform: scale(0.85);
    }

    .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {
        height: 100% !important;
    }
    

    .item-container {
        display: block;
    }

    #todo-title {
        max-width: 80%;
    }

    .item-left-container {
        margin-bottom: 20px;
        min-width: 250px;
    }

    .item-right-container {
        width: 100%;
        padding-left: 0;
    }

    .item-icons-container {
        width: 20%;
    }

    #emoji {
        font-size: 50px;
    }

    .emoji-container > p {
        font-size: 25px;
    }

    #footer {
        display: none;
    }

    .clear-data-container {
        position: unset;
    }

    #clear-data-btn {
        /* position: absolute;
        bottom: 25px;
        right: 30px; */
        display: none;
    }

    #settings-icon {
        margin: 20px;
    }


}

